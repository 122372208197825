import React, {useEffect,useState} from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { analyticserieService as service, analyticsmanufactureService} from '../../../../services';
import CrudEmpty from "../../../crud/CrudEmpty"
import styled from "styled-components";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer, PieChart, Pie, BarChart, Bar, Cell } from 'recharts';
import { ToastContainer, toast } from 'react-toastify';
import CustomPaginationActionsTable from '../../../table/Table';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../../../filter/Filter';
const moment = require("moment");



export default function SerieCountry() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterVisit, setFilterVisit] = React.useState({from: moment().add(-30,'days').format("YYYY-MM-DD")+"T00:00:00Z", to:moment().format("YYYY-MM-DD")+"T00:00:00Z", day:true,week:false,month:false, code: "US", serie:null});
  const [dataEvolutionVisit, setDataEvolutionVisit] = React.useState<any>([]);
  const [dataEvolutionSearch, setDataEvolutionSearch] = React.useState<any>([]);
  const [dataEvolutionSearchMarket, setDataEvolutionSearchMarket] = React.useState<any>([]);
  const [visitData, setVisitData] = React.useState<any>(null);
  const [visitDataPie, setVisitDataPie] = React.useState<any>([]);
  const [searchData, setSearchData] = React.useState<any>(null);
  const [searchDataPie, setSearchDataPie] = React.useState<any>([]);
  const [searchMarketData, setSearchMarketData] = React.useState<any>(null);
  const [searchDataMarketPie, setSearchDataMarketPie] = React.useState<any>([]);
  const [visitTop10, setVisitTop10] = React.useState<any>([]);
  const [searchTop10, setSearchTop10] = React.useState<any>([]);
  const [marketTop10, setMarketTop10] = React.useState<any>([]);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const createEstructureFilter =  [
    {   
      type:"autocomplete",
      name:"code",
      service: analyticsmanufactureService.comboCountry
    }, 
    {
        type:"autocomplete",
        name: "serie",
        service: service.comboserie,
        col: 3
    },
    {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 3
    },
    {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
    }
];


  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);


  const searchEvolutionVisit = (filterVisit) => {

       if(!filterVisit.code) {
            toast.error(t('seriecountry.error.code'), {position: toast.POSITION.TOP_CENTER })
       } else if(!filterVisit.from) {
            toast.error(t('seriecountry.error.from'), {position: toast.POSITION.TOP_CENTER })
       } else if(!filterVisit.to) {
            toast.error(t('seriecountry.error.to'), {position: toast.POSITION.TOP_CENTER })
       } else if(!filterVisit.code) {
        toast.error(t('seriecountry.error.serie'), {position: toast.POSITION.TOP_CENTER })
      }  else {
          let december = moment("2022-12-01");
          let daysDiffStart = moment(filterVisit.from).diff(december, 'days');

          if(daysDiffStart < 0){
            toast.error(t('seriecountry.error.start'), {position: toast.POSITION.TOP_CENTER })
          } else {
            let daysDiff = moment(filterVisit.to).diff(moment(filterVisit.from), 'days');
            if(daysDiff < 0){
                toast.error(t('seriecountry.error.diff'), {position: toast.POSITION.TOP_CENTER })
            } else {
                 filterVisit['day'] = daysDiff < 32;
                 filterVisit['week'] = daysDiff >= 32 && daysDiff < 64;
                 filterVisit['month'] = daysDiff >= 64;

                 service.countryVisitSpecific(filterVisit, (data, error) => {
                     if(data && data.data) {
                         setDataEvolutionVisit(data.data);
                     }
                 });
 
                 service.countrySearchSpecific(filterVisit, (data, error) => {
                     if(data && data.data) {
                         setDataEvolutionSearch(data.data);
                     }
                 });
 
 
                 service.countrySearchMarketSpecific(filterVisit, (data, error) => {
                     if(data && data.data) {
                         setDataEvolutionSearchMarket(data.data);
                     }
                 });
 
                 service.countryVisitData(filterVisit, (data, error) => {
                     if(data && data.data) {
                         setVisitData(data.data);
                         
                         if(data.data.ranking){
                             let d = [{value:+data.data.total - +data.data.ranking.ammount,name:t("seriecountry.othersvisit")},{value:+data.data.ranking.ammount, name:t("seriecountry.youvisit")},{value:+data.data.totalMy, name:t("serieone.youothervisit")}];
                             setVisitDataPie(d);
                         }
                     }
                 });
 
 
                 service.countrySearchData(filterVisit, (data, error) => {
                     if(data && data.data) {
                         setSearchData(data.data);
                         
                         if(data.data.ranking){
                             let d = [{value:+data.data.total - +data.data.ranking.ammount,name:t("seriecountry.otherssearch")},{value:+data.data.ranking.ammount, name:t("seriecountry.yousearch")},{value:+data.data.totalMy, name:t("serieone.youothervisit")}];
                             setSearchDataPie(d);
                         }
                     }
                 });
 
 
                 service.countrySearchMarketData(filterVisit, (data, error) => {
                     if(data && data.data) {
                         setSearchMarketData(data.data);
                         
                         if(data.data.ranking){
                             let d = [{value:+data.data.total - +data.data.ranking.ammount,name:t("seriecountry.otherssearch")},{value:+data.data.ranking.ammount, name:t("seriecountry.yousearch")},{value:+data.data.totalMy, name:t("serieone.youothervisit")}];
                             setSearchDataMarketPie(d);
                         }
                     }
                 });
 
 
                 service.countryVisitTop10Date(filterVisit, (data, error) => {
                     if(data && data.data) {
                         setVisitTop10(data.data);
                     }
                 });

                 service.countrySearchTop10Date(filterVisit, (data, error) => {
                    if(data && data.data) {
                        setSearchTop10(data.data);
                    }
                });

                 
                service.countrySearchMarketTop10Date(filterVisit, (data, error) => {
                    if(data && data.data) {
                        setMarketTop10(data.data);
                    }
                });
 
          }

           
        }

    }
        
  }

  const searchFilter = (filter) => {

    setFilterVisit(currentValueform => ({...currentValueform, ...filter}));
    searchEvolutionVisit(filter);
  }

  useEffect(() => {
    if(filterVisit.serie){
        searchEvolutionVisit(filterVisit);
    }
  }, []);

  const paintvalue = {
    codeCountry: (value) => {
        if (value) {
          return <img src={"/"+value.toLowerCase()+".png"} style={{width:"40px"}} />
        } else {
          return <></>
        }
    },
  }

  return (
    <CrudEmpty i18n={"seriecountry"}>
        <Grid container style={{background:"white", padding: width > 767?"0.3em 1em 1em":"0.3em 0.2em 1em", borderRadius: "3px", minHeight: "calc(100vh - 250px)", maxWidth:"100%", paddingTop:"1.2em"}}>
        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <Filter t={t} i18n={"seriecountry"} searchFilter={searchFilter} filter={filterVisit} titlebutton={t("seriecountry.titlebutton")} estructure={createEstructureFilter}  hiddenButton={true} expanded={true} isHiddenCancel={true}/> 
        </Grid>

        {!filterVisit.serie && <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <h4 style={{textAlign:"center",color:"red"}}>{t('serieone.error.serie')}</h4>
            </Grid>
        }

        {filterVisit.serie && <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <Grid container>
                <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.top.visit")}</h3>
                            <h6>{t("seriecountry.top.visitsubtitle")}</h6>
                            <Grid container>
                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                    <CustomPaginationActionsTable hiddenpagination={true}  t={t} i18n={"seriecountry"}  rows={visitTop10} fields={["serie","ammount","ranking"]} fieldId={"id"} rowsPerPage={10} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                                </Grid> 
                            </Grid>
                    </PanelRounded> 
                </Grid>
                <Grid item sm={3} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.ranking.visit")}</h3>
                            <h6>{t("seriecountry.ranking.visitsubtitle")}</h6>
                            <Grid container>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    <span>{t('seriecountry.rankingposition')}</span>
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    {visitData && visitData.ranking && visitData.ranking.ranking && <b>{visitData.ranking.ranking}</b>}
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    <span>{t('seriecountry.visittotal')} </span>
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    {visitData && visitData.ranking && visitData.ranking.ammount && <b>{visitData.ranking.ammount}</b>}
                                </Grid>

                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                {visitDataPie && visitDataPie.length > 0 &&    <ResponsiveContainer aspect={ width> 767 ? 1.2 : 1.0}  width='100%'>
                                            <PieChart>
                                                <Tooltip />
                                                <Legend />
                                                <Pie data={visitDataPie} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d"  label>{visitDataPie.map((entry, index) => <Cell fill={index == 0?"#82ca9d":index == 1? "#8884d8" : "#2f3354"}/>)}</Pie>
                                            </PieChart>
                                        </ResponsiveContainer> }
                                </Grid>
                                    
                            </Grid>
                    </PanelRounded> 
                </Grid>
                <Grid item sm={9} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.evolution.title")}</h3>
                            <h6>{t("seriecountry.evolution.subtitle")}</h6>
                            <Grid container>
                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                    {dataEvolutionVisit && dataEvolutionVisit.length > 0 && 
                                        <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                            <LineChart data={dataEvolutionVisit}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="dateLabel" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line dataKey="ammount" stroke="#82ca9d" name={t('seriecountry.visit')}/>
                                            </LineChart>
                                        </ResponsiveContainer>}
                                </Grid>
                                <Grid item xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                                    {dataEvolutionVisit && dataEvolutionVisit.length > 0 && 
                                        <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                            <LineChart data={dataEvolutionVisit}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="dateLabel" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line dataKey="ranking" stroke="#8884d8" name={t('seriecountry.rankingposition')}/>
                                            </LineChart>
                                        </ResponsiveContainer>}
                                </Grid>
                            </Grid>
                    </PanelRounded> 
                </Grid>
                <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.ranking.visitdata")}</h3>
                            <h6>{t("seriecountry.ranking.visitsubtitledata")}</h6>
                            <Grid container>
                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                        <CustomPaginationActionsTable hiddenpagination={true}  t={t} i18n={"seriecountry"}  rows={dataEvolutionVisit} fields={["dateLabel","ammount","ranking"]} fieldId={"id"} rowsPerPage={10} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                                </Grid> 
                            </Grid>
                    </PanelRounded> 
                </Grid>
                
                <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.top.search")}</h3>
                            <h6>{t("seriecountry.top.searchsubtitle")}</h6>
                            <Grid container>
                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                    <CustomPaginationActionsTable hiddenpagination={true}  t={t} i18n={"seriecountry"}  rows={searchTop10} fields={["serie","ammount","ranking"]} fieldId={"id"} rowsPerPage={10} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                                </Grid> 
                            </Grid>
                    </PanelRounded> 
                </Grid>
                <Grid item sm={3} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768} >
                            <h3>{t("seriecountry.ranking.search")}</h3>
                            <h6>{t("seriecountry.ranking.searchsubtitle")}</h6>
                            <Grid container>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    <span>{t('seriecountry.rankingposition')}</span>
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    {searchData && searchData.ranking && searchData.ranking.ranking && <b>{searchData.ranking.ranking}</b>}
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    <span>{t('seriecountry.searchtotal')} </span>
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    {searchData && searchData.ranking && searchData.ranking.ammount && <b>{searchData.ranking.ammount}</b>}
                                </Grid>

                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em",}}>
                                {searchDataPie && searchDataPie.length > 0 &&    <ResponsiveContainer aspect={ width> 767 ? 1.2 : 1.0}  width='100%'>
                                            <PieChart>
                                                <Tooltip />
                                                <Legend />
                                                <Pie data={searchDataPie} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d"  label>{visitDataPie.map((entry, index) => <Cell fill={index == 0?"#82ca9d":index == 1? "#8884d8" : "#2f3354"}/>)}</Pie>
                                            </PieChart>
                                        </ResponsiveContainer> }
                                </Grid>
                            </Grid>
                    </PanelRounded> 
                </Grid>
                <Grid item sm={9} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.evolutionsearch.title")}</h3>
                            <h6>{t("seriecountry.evolutionsearch.subtitle")}</h6>
                            <Grid container>
                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                    {dataEvolutionSearch && dataEvolutionSearch.length > 0 && 
                                        <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                            <LineChart data={dataEvolutionSearch}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="dateLabel" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line dataKey="ammount" stroke="#82ca9d" name={t('seriecountry.search')}/>
                                            </LineChart>
                                        </ResponsiveContainer>}
                                </Grid>
                                <Grid item xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                                    {dataEvolutionSearch && dataEvolutionSearch.length > 0 && 
                                        <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                            <LineChart data={dataEvolutionSearch}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="dateLabel" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line dataKey="ranking" stroke="#8884d8" name={t('seriecountry.rankingposition')}/>
                                            </LineChart>
                                        </ResponsiveContainer>}
                                </Grid>
                            </Grid>
                    </PanelRounded> 
                </Grid>
                <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.ranking.searchdata")}</h3>
                            <h6>{t("seriecountry.ranking.searchsubtitledata")}</h6>
                            <Grid container>
                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                        <CustomPaginationActionsTable hiddenpagination={true} t={t} i18n={"seriecountry"}  rows={dataEvolutionSearch} fields={["dateLabel","ammount","ranking"]} fieldId={"id"} rowsPerPage={10} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                                </Grid> 
                            </Grid>
                    </PanelRounded> 
                </Grid>
                
                <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.top.market")}</h3>
                            <h6>{t("seriecountry.top.marketsubtitle")}</h6>
                            <Grid container>
                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                    <CustomPaginationActionsTable hiddenpagination={true}  t={t} i18n={"seriecountry"}  rows={marketTop10} fields={["serie","ammount","ranking"]} fieldId={"id"} rowsPerPage={10} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                                </Grid> 
                            </Grid>
                    </PanelRounded> 
                </Grid>
                <Grid item sm={3} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.ranking.market")}</h3>
                            <h6>{t("seriecountry.ranking.marketsubtitle")}</h6>
                            <Grid container>
                            <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    <span>{t('seriecountry.rankingposition')}</span>
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    {searchMarketData && searchMarketData.ranking && searchMarketData.ranking.ranking && <b>{searchMarketData.ranking.ranking}</b>}
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    <span>{t('seriecountry.searchtotal')} </span>
                                </Grid>
                                <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                                    {searchMarketData && searchMarketData.ranking && searchMarketData.ranking.ammount && <b>{searchMarketData.ranking.ammount}</b>}
                                </Grid>

                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                {searchDataMarketPie && searchDataMarketPie.length > 0 &&   <ResponsiveContainer aspect={ width> 767 ? 1.2 : 1.0}  width='100%'>
                                            <PieChart>
                                                <Tooltip />
                                                <Legend />
                                                <Pie data={searchDataMarketPie} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d"  label>{visitDataPie.map((entry, index) => <Cell fill={index == 0?"#82ca9d":index == 1? "#8884d8" : "#2f3354"}/>)}</Pie>
                                            </PieChart>
                                        </ResponsiveContainer> }
                                </Grid>
                            </Grid>
                    </PanelRounded> 
                </Grid>
                <Grid item sm={9} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.evolutionmarket.title")}</h3>
                            <h6>{t("seriecountry.evolutionmarket.subtitle")}</h6>
                            <Grid container>
                            <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                    {dataEvolutionSearchMarket && dataEvolutionSearchMarket.length > 0 && 
                                        <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                            <LineChart data={dataEvolutionSearchMarket}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="dateLabel" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line dataKey="ammount" stroke="#82ca9d" name={t('seriecountry.search')}/>
                                            </LineChart>
                                        </ResponsiveContainer>}
                                </Grid>
                                <Grid item xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                                    {dataEvolutionSearchMarket && dataEvolutionSearchMarket.length > 0 && 
                                        <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                            <LineChart data={dataEvolutionSearchMarket}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="dateLabel" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line dataKey="ranking" stroke="#8884d8" name={t('seriecountry.rankingposition')}/>
                                            </LineChart>
                                        </ResponsiveContainer>}
                                </Grid>
                            </Grid>
                    </PanelRounded> 
                </Grid>

                <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                    <PanelRounded mobile={width < 768}>
                            <h3>{t("seriecountry.ranking.marketdata")}</h3>
                            <h6>{t("seriecountry.ranking.marketsubtitledata")}</h6>
                            <Grid container>
                                <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                        <CustomPaginationActionsTable hiddenpagination={true}  t={t} i18n={"seriecountry"}  rows={dataEvolutionSearchMarket} fields={["dateLabel","ammount","ranking"]} fieldId={"id"} rowsPerPage={10} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                                </Grid> 
                            </Grid>
                    </PanelRounded> 
                </Grid>
            </Grid>
        </Grid>}
        
        </Grid>

        <ToastContainer />
    </CrudEmpty>
  );
}


const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: props.mobile? '1em 0.2em' : '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    minHeight:"80px",
    "& h3":{
        color: "black",
        position: "absolute",
        fontSize: "1em",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    },
    "& h6": {
        color: "#434343",
        position: "absolute",
        top: "-1.2em",
        fontSize:"0.8em",
        left: "3.5em",
        fontStyle:"italic",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",
    }

  }));