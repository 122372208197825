import BaseService from './_base.service';

const URL = "/analytics/manufacture";

export const analyticsmanufactureService = {
    visit,
    search,
    searchTop10,
    visitTop10,
    searchMarket,
    visitData,
    searchData,
    searchMarketData,
    serachCountryTop10,
    visitCountryTop10,
    visitTop10Date,
    searchTop10Date,
    marketTop10Date,
    serachCountry,
    visitCountry,
    visitMarketTop10,
    visitMarket,
    countryVisitTop10Date,
    countrySearchTop10Date,
    countrySearchSpecific,
    countryVisitSpecific,
    countryVisitData,
    countrySearchData,
    countrySearchMarketData,
    countrySearchMarketSpecific,
    countrySearchMarketTop10Date,
    comboCountry
};



function visit(filter,callback) {
    return BaseService.post(URL+"/visit", filter, callback, true)
}
function search(filter,callback) {
    return BaseService.post(URL+"/search", filter, callback, true)
}
function searchTop10(filter,callback) {
    return BaseService.post(URL+"/search/top10", filter, callback, true)
}
function visitTop10(filter,callback) {
    return BaseService.post(URL+"/visit/top10", filter, callback, true)
}
function searchMarket(filter,callback) {
    return BaseService.post(URL+"/market/search", filter, callback, true)
}
function visitData(filter,callback) {
    return BaseService.post(URL+"/visit/data", filter, callback, true)
}
function searchData(filter,callback) {
    return BaseService.post(URL+"/search/data", filter, callback, true)
}
function searchMarketData(filter,callback) {
    return BaseService.post(URL+"/market/search/data", filter, callback, true)
}
function serachCountryTop10(filter,callback) {
    return BaseService.post(URL+"/country/search/top10", filter, callback, true)
}
function visitCountryTop10(filter,callback) {
    return BaseService.post(URL+"/country/visit/top10", filter, callback, true)
}
function visitTop10Date(filter,callback) {
    return BaseService.post(URL+"/visit/top10/date", filter, callback, true)
}
function searchTop10Date(filter,callback) {
    return BaseService.post(URL+"/search/top10/date", filter, callback, true)
}
function marketTop10Date(filter,callback) {
    return BaseService.post(URL+"/market/top10/date", filter, callback, true)
}
function serachCountry(filter,callback) {
    return BaseService.post(URL+"/country/search", filter, callback, true)
}
function visitCountry(filter,callback) {
    return BaseService.post(URL+"/country/visit", filter, callback, true)
}
function visitMarketTop10(filter,callback) {
    return BaseService.post(URL+"/country/market/top10", filter, callback, true)
}
function visitMarket(filter,callback) {
    return BaseService.post(URL+"/country/market", filter, callback, true)
}
function countryVisitTop10Date(filter,callback) {
    return BaseService.post(URL+"/country/visit/top10/date", filter, callback, true)
}
function countrySearchTop10Date(filter,callback) {
    return BaseService.post(URL+"/country/search/top10/date", filter, callback, true)
}
function countrySearchSpecific(filter,callback) {
    return BaseService.post(URL+"/country/specific/search", filter, callback, true)
}
function countryVisitSpecific(filter,callback) {
    return BaseService.post(URL+"/country/specific/visit", filter, callback, true)
}
function countryVisitData(filter,callback) {
    return BaseService.post(URL+"/country/specific/visit/data", filter, callback, true)
}
function countrySearchData(filter,callback) {
    return BaseService.post(URL+"/country/specific/search/data", filter, callback, true)
}
function countrySearchMarketData(filter,callback) {
    return BaseService.post(URL+"/country/specific/search/market/data", filter, callback, true)
}
function countrySearchMarketSpecific(filter,callback) {
    return BaseService.post(URL+"/country/specific/search/market/", filter, callback, true)
}
function countrySearchMarketTop10Date(filter,callback) {
    return BaseService.post(URL+"/country/search/market/top10/date", filter, callback, true)
}
function comboCountry(callback) {
    return BaseService.get(URL+"/combo/country", callback, true)
}


