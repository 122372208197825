import React, {  useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Form from "../form/Form"
import styled from "styled-components";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export default function Filter(props) {
  const t = props.t;
  const [filter, setFilter] = React.useState(props.filter?props.filter:{});
  const [estructure, setEstructure] = React.useState(props.estructure?props.estructure:[]);
  const [hiddenButton, setHiddenButton] = React.useState(props.hiddenButton?props.hiddenButton:false);
  const [labelsearch, setLabelsearch] = React.useState(props.labelsearch?props.labelsearch:t("general.search"));
  const [labelclean, setLabelclean] = React.useState(props.labelclean?props.labelclean:t("general.clean"));
  const [labeltitle, setLabeltitle] = React.useState(props.labeltitle?props.labeltitle:t("general.search"));
  const [cleanInOK, setCleanInOK] = React.useState(props.cleanInOK?props.cleanInOK:false);
  const [onlyRead, setOnlyRead] = React.useState(props.onlyRead?props.onlyRead:false);
  const [expanded, setExpanded] = React.useState(props.expanded?props.expanded:false);
  const [notopeninit,setNotopeninit] =  React.useState(props.notopeninit?props.notopeninit:false);
  const [forceExpand,setForceExpand] =  React.useState(props.forceExpand?props.forceExpand:false);
  const iconfilter = props.iconfilter ? props.iconfilter  : <FilterAltIcon sx={{marginBottom:"-7px"}} />;


  useEffect(() => {
    setFilter(props.filter);
    if(props.filter["id"] && props.filter["id"] > 0 && !notopeninit){
      setExpanded(true);
    }
  }, [props.filter]);

  useEffect(() => {
    if(forceExpand){
      setExpanded(true);
    }

  }, [props.forceExpand])

  return (
      <DivPanelFilter>
        <DivPanelButton>
          {!hiddenButton?<Button component={Link} to={props.urlnew?props.urlnew:"#"} variant="contained" color="secondary" style={{marginLeft:'30px'}}> {props.titlebutton?props.titlebutton:'Crear nuevo'} </Button>:''}
        </DivPanelButton>
      
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{borderRadius:"4px !important", border:"none !important", "& .MuiAccordionSummary-root": {border:"none !important"}}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography> {iconfilter} <label style={{fontSize:'1em',fontWeight:'bold'}}>{labeltitle}</label>  </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Form t={t} i18n={props.i18n + ".filter"} objectform={filter} fields={estructure}  searchFilter={props.searchFilter} cleanFilter={props.cleanFilter} labelsave={labelsearch} labelcancel={labelclean} cleanInOK={cleanInOK} isHiddenSave={onlyRead} isHiddenCancel={onlyRead || props.isHiddenCancel}/>
          </AccordionDetails>
        </Accordion>
      </DivPanelFilter>
  );

}


const DivPanelFilter =  styled.div`
  margin-bottom: 3em;
`;

const DivPanelButton =  styled.div`
  text-align: right;
  margin-bottom: 10px;
`;
