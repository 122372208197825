import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

export default function Copyright(props) {
    const { t, i18n } = useTranslation();

    return (
        <Typography variant="body2" style={{color:props.color?props.color:"white"}} align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://sotuomartino.es/">
            {t('general.nameapp')}
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      );

}