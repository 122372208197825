import BaseService from './_base.service';

const URL = "/analytics/attr";

export const analyticattrService = {
    attrlens,
    attrlenscountry,
    mark,
    markcountry,
    coverage,
    coveragecountry
};



function attrlens(filter,callback) {
    return BaseService.post(URL+"/typelens", filter, callback, true)
}
function attrlenscountry(filter,callback) {
    return BaseService.post(URL+"/typelens/country/", filter, callback, true)
}
function mark(filter,callback) {
    return BaseService.post(URL+"/mark", filter, callback, true)
}
function markcountry(filter,callback) {
    return BaseService.post(URL+"/mark/country/", filter, callback, true)
}
function coverage(filter,callback) {
    return BaseService.post(URL+"/coverage", filter, callback, true)
}
function coveragecountry(filter,callback) {
    return BaseService.post(URL+"/coverage/country/", filter, callback, true)
}
