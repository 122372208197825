import {BrowserRouter as Router, Route, Redirect,Switch } from 'react-router-dom';
import './App.css';
import './language/i18n';
import { isNotLoginLogin } from './utils/session';
import LoginRoute from './components/route/LoginRoute';
import Login from './components/pages/login/Login';
import Dashboard from './components/pages/dasboard/Dashboard';
import PrivateRoute from './components/route/PrivateRoute';
import FormMyAccount from './components/pages/users/FormMyAccount';
import Inwork from './components/pages/inwork/Inwork';
import BrandGeneral from './components/pages/brand/general/BrandGeneral';
import BrandCountry from './components/pages/brand/country/BrandCountry';
import SerieGeneral from './components/pages/serie/general/SerieGeneral';
import SerieOne from './components/pages/serie/one/SerieOne';
import SerieCountry from './components/pages/serie/country/SerieCountry';
import AttrGeneral from './components/pages/attr/general/AttrGeneral';
import AttrCountry from './components/pages/attr/country/AttrCountry';
import Contact from './components/pages/contact/Contact';

export default function App() {

  const isLoggedNotIn = isNotLoginLogin();

  return (
    <>
    <Router>
      <Switch>
        <LoginRoute path="/login" component={Login} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/myaccount/:id" component={FormMyAccount} />
        <PrivateRoute path="/brand" component={BrandGeneral} />
        <PrivateRoute path="/brandcountry" component={BrandCountry} />
        <PrivateRoute path="/branduser" component={Inwork} />
        <PrivateRoute path="/serie" component={SerieGeneral} />
        <PrivateRoute path="/serieone" component={SerieOne} />
        <PrivateRoute path="/seriecountry" component={SerieCountry} />
        <PrivateRoute path="/serieuser" component={Inwork} />
        <PrivateRoute path="/search" component={AttrGeneral} />
        <PrivateRoute path="/searchcountry" component={AttrCountry} />
        <PrivateRoute path="/searchinmarketplace" component={Inwork} />
        <PrivateRoute path="/contact" component={Contact} />
      
        

        {isLoggedNotIn ? <Route path="/" component={Login} /> : <PrivateRoute path="/" component={Dashboard} />}
      </Switch>
    </Router>
    </>
  );
}