import React, {useEffect,useState} from 'react';
import Layout from '../../layout/Layout';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { analyticsmanufactureService as service} from '../../../services';
import CrudEmpty from "../../crud/CrudEmpty"
import styled from "styled-components";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer, PieChart, Pie, BarChart, Bar } from 'recharts';
const moment = require("moment");



export default function Dashboard() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterVisit, setFilterVisit] = React.useState({from: moment().add(-30,'days').format("YYYY-MM-DD")+"T00:00:00Z", to:moment().format("YYYY-MM-DD")+"T00:00:00Z", day:true,week:false,month:false});
  const [dataEvolutionVisit, setDataEvolutionVisit] = React.useState<any>([]);
  const [dataEvolutionSearch, setDataEvolutionSearch] = React.useState<any>([]);
  const [dataEvolutionSearchMarket, setDataEvolutionSearchMarket] = React.useState<any>([]);
  const [visitData, setVisitData] = React.useState<any>(null);
  const [visitDataPie, setVisitDataPie] = React.useState<any>([]);
  const [searchData, setSearchData] = React.useState<any>(null);
  const [searchDataPie, setSearchDataPie] = React.useState<any>([]);
  const [searchMarketData, setSearchMarketData] = React.useState<any>(null);
  const [searchDataMarketPie, setSearchDataMarketPie] = React.useState<any>([]);
  const [searchCountryTop10, setSearchCountryTop10] = React.useState<any>([]);
  const [visitCountryTop10, setVisitCountryTop10] = React.useState<any>([]);
  const [width, setWidth] = useState<number>(window.innerWidth);


  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);


  const searchEvolutionVisit = () => {
        service.visit(filterVisit, (data, error) => {
            if(data && data.data) {
                setDataEvolutionVisit(data.data);
            }
        });

        service.search(filterVisit, (data, error) => {
            if(data && data.data) {
                setDataEvolutionSearch(data.data);
            }
        });


        service.searchMarket(filterVisit, (data, error) => {
            if(data && data.data) {
                setDataEvolutionSearchMarket(data.data);
            }
        });

        service.visitData(filterVisit, (data, error) => {
            if(data && data.data) {
                setVisitData(data.data);
                
                if(data.data.ranking){
                    let d = [{value:+data.data.total - +data.data.ranking.ammount,name:t("dashboard.othersvisit")},{value:+data.data.ranking.ammount, name:t("dashboard.youvisit")}];
                    setVisitDataPie(d);
                }
            }
        });


        service.searchData(filterVisit, (data, error) => {
            if(data && data.data) {
                setSearchData(data.data);
                
                if(data.data.ranking){
                    let d = [{value:+data.data.total - +data.data.ranking.ammount,name:t("dashboard.otherssearch")},{value:+data.data.ranking.ammount, name:t("dashboard.yousearch")}];
                    setSearchDataPie(d);
                }
            }
        });


        service.searchMarketData(filterVisit, (data, error) => {
            if(data && data.data) {
                setSearchMarketData(data.data);
                
                if(data.data.ranking){
                    let d = [{value:+data.data.total - +data.data.ranking.ammount,name:t("dashboard.otherssearch")},{value:+data.data.ranking.ammount, name:t("dashboard.yousearch")}];
                    setSearchDataMarketPie(d);
                }
            }
        });

        service.serachCountryTop10(filterVisit, (data, error) => {
            if(data && data.data) {
                setSearchCountryTop10(data.data);
            }
        });

        service.visitCountryTop10(filterVisit, (data, error) => {
            if(data && data.data) {
                setVisitCountryTop10(data.data);
            }
        });


        
  }

  useEffect(() => {
     searchEvolutionVisit();
  }, []);


  return (
    <CrudEmpty i18n={"dashboard"}>
        <Grid container style={{background:"white", padding: width > 767?"0.3em 1em 1em":"0.3em 0.2em 1em", borderRadius: "3px", minHeight: "calc(100vh - 250px)", maxWidth:"100%", paddingTop:"1.2em"}}>
        <Grid item sm={3} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("dashboard.ranking.visit")}</h3>
                    <h6>{t("dashboard.ranking.visitsubtitle")}</h6>
                    <Grid container>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            <span>{t('dashboard.rankingposition')}</span>
                        </Grid>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            {visitData && visitData.ranking && visitData.ranking.ranking && <b>{visitData.ranking.ranking}</b>}
                        </Grid>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            <span>{t('dashboard.visittotal')} </span>
                        </Grid>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            {visitData && visitData.ranking && visitData.ranking.ammount && <b>{visitData.ranking.ammount}</b>}
                        </Grid>

                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                        {visitDataPie && visitDataPie.length > 0 &&    <ResponsiveContainer aspect={ width> 767 ? 1.2 : 1.0}  width='100%'>
                                    <PieChart>
                                        <Tooltip />
                                        <Legend />
                                        <Pie data={visitDataPie} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d"  label/>
                                    </PieChart>
                                </ResponsiveContainer> }
                        </Grid>
                            
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={9} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("dashboard.evolution.title")}</h3>
                    <h6>{t("dashboard.evolution.subtitle")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            {dataEvolutionVisit && dataEvolutionVisit.length > 0 && 
                                <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                    <LineChart data={dataEvolutionVisit}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line dataKey="ammount" stroke="#82ca9d" name={t('dashboard.visit')}/>
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                            {dataEvolutionVisit && dataEvolutionVisit.length > 0 && 
                                <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                    <LineChart data={dataEvolutionVisit}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line dataKey="ranking" stroke="#8884d8" name={t('dashboard.rankingposition')}/>
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={3} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768} >
                    <h3>{t("dashboard.ranking.search")}</h3>
                    <h6>{t("dashboard.ranking.searchsubtitle")}</h6>
                    <Grid container>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            <span>{t('dashboard.rankingposition')}</span>
                        </Grid>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            {searchData && searchData.ranking && searchData.ranking.ranking && <b>{searchData.ranking.ranking}</b>}
                        </Grid>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            <span>{t('dashboard.searchtotal')} </span>
                        </Grid>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            {searchData && searchData.ranking && searchData.ranking.ammount && <b>{searchData.ranking.ammount}</b>}
                        </Grid>

                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em",}}>
                        {searchDataPie && searchDataPie.length > 0 &&    <ResponsiveContainer aspect={ width> 767 ? 1.2 : 1.0}  width='100%'>
                                    <PieChart>
                                        <Tooltip />
                                        <Legend />
                                        <Pie data={searchDataPie} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d"  label/>
                                    </PieChart>
                                </ResponsiveContainer> }
                        </Grid>
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={9} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("dashboard.evolutionsearch.title")}</h3>
                    <h6>{t("dashboard.evolutionsearch.subtitle")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            {dataEvolutionSearch && dataEvolutionSearch.length > 0 && 
                                <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                    <LineChart data={dataEvolutionSearch}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line dataKey="ammount" stroke="#82ca9d" name={t('dashboard.search')}/>
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                            {dataEvolutionSearch && dataEvolutionSearch.length > 0 && 
                                <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                    <LineChart data={dataEvolutionSearch}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line dataKey="ranking" stroke="#8884d8" name={t('dashboard.rankingposition')}/>
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={3} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("dashboard.ranking.market")}</h3>
                    <h6>{t("dashboard.ranking.marketsubtitle")}</h6>
                    <Grid container>
                    <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            <span>{t('dashboard.rankingposition')}</span>
                        </Grid>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            {searchMarketData && searchMarketData.ranking && searchMarketData.ranking.ranking && <b>{searchMarketData.ranking.ranking}</b>}
                        </Grid>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            <span>{t('dashboard.searchtotal')} </span>
                        </Grid>
                        <Grid item xs={6} style={{marginTop:"30px", padding:"0.2em"}}>
                            {searchMarketData && searchMarketData.ranking && searchMarketData.ranking.ammount && <b>{searchMarketData.ranking.ammount}</b>}
                        </Grid>

                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                        {searchDataMarketPie && searchDataMarketPie.length > 0 &&   <ResponsiveContainer aspect={ width> 767 ? 1.2 : 1.0}  width='100%'>
                                    <PieChart>
                                        <Tooltip />
                                        <Legend />
                                        <Pie data={searchDataMarketPie} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d"  label/>
                                    </PieChart>
                                </ResponsiveContainer> }
                        </Grid>
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={9} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("dashboard.evolutionmarket.title")}</h3>
                    <h6>{t("dashboard.evolutionmarket.subtitle")}</h6>
                    <Grid container>
                    <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            {dataEvolutionSearchMarket && dataEvolutionSearchMarket.length > 0 && 
                                <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                    <LineChart data={dataEvolutionSearchMarket}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line dataKey="ammount" stroke="#82ca9d" name={t('dashboard.search')}/>
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                            {dataEvolutionSearchMarket && dataEvolutionSearchMarket.length > 0 && 
                                <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                    <LineChart data={dataEvolutionSearchMarket}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line dataKey="ranking" stroke="#8884d8" name={t('dashboard.rankingposition')}/>
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                    </Grid>
            </PanelRounded> 
        </Grid>

        <Grid item sm={6} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("dashboard.country.visit")}</h3>
                    <h6>{t("dashboard.country.visitsubtitle")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                        {visitCountryTop10 && visitCountryTop10.length > 0 && <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                <BarChart data={visitCountryTop10}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="country" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="ranking" fill="#8884d8" name={t('dashboard.rankingposition')}/>
                                    <Bar dataKey="ammount" fill="#82ca9d" name={t('dashboard.numsearch')} />
                                </BarChart>
                            </ResponsiveContainer> }
                        </Grid>
                            
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={6} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("dashboard.country.search")}</h3>
                    <h6>{t("dashboard.country.searchsubtitle")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            {searchCountryTop10 && searchCountryTop10.length > 0 && <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                <BarChart data={searchCountryTop10}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="country" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="ranking" fill="#8884d8" name={t('dashboard.rankingposition')}/>
                                    <Bar dataKey="ammount" fill="#82ca9d" name={t('dashboard.numsearch')} />
                                </BarChart>
                            </ResponsiveContainer> }
                        </Grid>
                    </Grid>
            </PanelRounded> 
        </Grid>

        </Grid>

    </CrudEmpty>
  );
}


const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: props.mobile? '1em 0.2em' : '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    minHeight:"80px",
    "& h3":{
        color: "black",
        position: "absolute",
        fontSize: "1em",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    },
    "& h6": {
        color: "#434343",
        position: "absolute",
        top: "-1.2em",
        fontSize:"0.8em",
        left: "3.5em",
        fontStyle:"italic",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",
    }

  }));