import React, {useEffect,useState} from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { analyticattrService as service, analyticsmanufactureService} from '../../../../services';
import CrudEmpty from "../../../crud/CrudEmpty"
import styled from "styled-components";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer, PieChart, Pie, BarChart, Bar, Cell } from 'recharts';
import { ToastContainer, toast } from 'react-toastify';
import CustomPaginationActionsTable from '../../../table/Table';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../../../filter/Filter';
const moment = require("moment");



export default function AttrCountry() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [colors, setColors] = React.useState<any>(["#0C0AF5","#FF7881","#0A48FF","#0279E8","#0AC9FF","#0AF5EA","#F75696","#D64BC8","#CE5FED","#924BD6","#7D56F7","#FFD996","#E88D61","#D761E8","#8E6BFF","#A8ADFF","#7DFFB3","#E8DA7D","#FF937D","#4D01EB","#FF4F2B","#4900E8","#E5E800","#FFAD7D","#0DFFCF","#9CFFEB","#E6E866","#C066E8","#FFAD7D","#0DD5FF","#FF782B","#9E00E8","#79E800","#FFC67D","#0DD7FF","#CDE6B3","#0DD8FF","#9B00E8","#FF620D","#EAE97E","#96EB7E","#A86FE8","#D49A68","#4F6AD1","#EC1900"]);
  const [filterVisit, setFilterVisit] = React.useState({from: moment().add(-30,'days').format("YYYY-MM-DD")+"T00:00:00Z", to:moment().format("YYYY-MM-DD")+"T00:00:00Z", day:true,week:false,month:false, code: "US"});
  
  const [attrlens, setAttrlens] = React.useState<any>([]);
  const [attrlensgra, setAttrlensgra] = React.useState<any>([]);
  const [attrlensColors, setAttrlensColors] = React.useState<any>([]);
  const [attrlensSerie, setAttrlensSerie] = React.useState<any>([]);
  const [attrlensPie, setAttrlensPie] = React.useState<any>([]);


  const [attrmark, setAttrmark] = React.useState<any>([]);
  const [attrmarkgra, setAttrmarkgra] = React.useState<any>([]);
  const [attrmarkColors, setAttrmarkColors] = React.useState<any>([]);
  const [attrmarkSerie, setAttrmarkSerie] = React.useState<any>([]);
  const [attrmarkSerieHidden, setAttrmarkSerieHidden] = React.useState<any>([]);
  const [attrmarkPie, setAttrmarkPie] = React.useState<any>([]);


  const [attrcoverage, setAttrcoverage] = React.useState<any>([]);
  const [attrcoveragegra, setAttrcoveragegra] = React.useState<any>([]);
  const [attrcoverageColors, setAttrcoverageColors] = React.useState<any>([]);
  const [attrcoverageSerie, setAttrcoverageSerie] = React.useState<any>([]);
  const [attrcoverageSerieHidden, setAttrcoverageSerieHidden] = React.useState<any>([]);
  const [attrcoveragePie, setAttrcoveragePie] = React.useState<any>([]);
  
  const [width, setWidth] = useState<number>(window.innerWidth);

  const createEstructureFilter =  [
    {   
        type:"autocomplete",
        name:"code",
        service: analyticsmanufactureService.comboCountry
      }, 
    {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 3
    },
    {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
    }
];


  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);


  const searchEvolutionVisit = (filterVisit) => {

        if(!filterVisit.code) {
            toast.error(t('attrcountry.error.country'), {position: toast.POSITION.TOP_CENTER })
        } else if(!filterVisit.from) {
            toast.error(t('attrcountry.error.from'), {position: toast.POSITION.TOP_CENTER })
        } else if(!filterVisit.to) {
            toast.error(t('attrcountry.error.to'), {position: toast.POSITION.TOP_CENTER })
        } else {
          let december = moment("2022-12-01");
          let daysDiffStart = moment(filterVisit.from).diff(december, 'days');

          if(daysDiffStart < 0){
            toast.error(t('attrcountry.error.start'), {position: toast.POSITION.TOP_CENTER })
          } else {
            let daysDiff = moment(filterVisit.to).diff(moment(filterVisit.from), 'days');
            if(daysDiff < 0){
                toast.error(t('attrcountry.error.diff'), {position: toast.POSITION.TOP_CENTER })
            } else {
                 filterVisit['day'] = daysDiff < 32;
                 filterVisit['week'] = daysDiff >= 32 && daysDiff < 64;
                 filterVisit['month'] = daysDiff >= 64;

                 service.attrlenscountry(filterVisit, (data, error) => {
                     if(data && data.data) {
                        

                        var pie:any[] = [];
                        var attr:any[] = [];
                        var attrArray:any[] = [];
                        var attrColors:any[] = [];
                        var countSerie = 0;
                        data.data.forEach(element => {
                           let key = element.code;
                           if(!attr[key]){
                               attr[key] = {key:key, name:element.name};
                               attrArray.push({key:key, name:element.name});

                               attrColors[key] = colors[countSerie % colors.length];
                               countSerie++;
                           }

                           if(!pie[key]){
                                pie[key] = 0;
                            }

                            pie[key] =  pie[key] + element["ammount"];
                        });

                        let byDate:any[] = [];

                         data.data.forEach(element => {
                             let date = element.dateLabel;
                             if(!byDate[date]){
                                byDate[date] = element;
                             }

                             let code = element.code;
                             byDate[date]["ammount"+code] = element["ammount"];
                             byDate[date]["ranking"+code] = element["ranking"];
                             
                         });

                         let aux:any[] = [];
                         for (const key in byDate) {
                            if (Object.prototype.hasOwnProperty.call(byDate, key)) {
                                const element = byDate[key];
                                aux.push(element);
                            }
                         }

                         let auxPie:any[] = [];
                         for (const key in pie) {
                            if (Object.prototype.hasOwnProperty.call(pie, key)) {
                                const element = pie[key];
                                auxPie.push({key:key,value:element});
                            }
                         }

                        setAttrlensColors(attrColors);
                        setAttrlensSerie(attrArray);
                        setAttrlens(data.data);
                        setAttrlensgra(aux);
                        setAttrlensPie(auxPie);


                     }
                });

                service.markcountry(filterVisit, (data, error) => {
                        if(data && data.data) {
                           
   
                           var attr:any[] = [];
                           var attrArray:any[] = [];
                           var seriesHidden:any[] = [];
                           var attrColors:any[] = [];
                           var pie:any[] = [];
                           var countSerie = 0;
                           data.data.forEach(element => {
                              let key = element.code;
                              if(!attr[key]){
                                  attr[key] = {key:key, name:element.name};
                                  attrArray.push({key:key, name:element.name});
                                  seriesHidden[key] = false;
   
                                  attrColors[key] = colors[countSerie % colors.length];
                                  countSerie++;
                              }

                              if(!pie[key]){
                                 pie[key] = 0;
                              }

                              pie[key] =  pie[key] + element["ammount"];

                           });
   
                           let byDate:any[] = [];
   
                            data.data.forEach(element => {
                                let date = element.dateLabel;
                                if(!byDate[date]){
                                   byDate[date] = element;
                                }
   
                                let code = element.code;
                                byDate[date]["ammount"+code] = element["ammount"];
                                byDate[date]["ranking"+code] = element["ranking"];
                                
                            });
   
                            let aux:any[] = [];
                            for (const key in byDate) {
                               if (Object.prototype.hasOwnProperty.call(byDate, key)) {
                                   const element = byDate[key];
                                   aux.push(element);
                               }
                            }


                            let auxPie:any[] = [];
                            for (const key in pie) {
                                if (Object.prototype.hasOwnProperty.call(pie, key)) {
                                    const element = pie[key];
                                    auxPie.push({key:key,value:element});
                                }
                            }
   
   
                           setAttrmarkColors(attrColors);
                           setAttrmarkSerie(attrArray);
                           setAttrmark(data.data);
                           setAttrmarkgra(aux);
                           setAttrmarkSerieHidden(seriesHidden);
                           setAttrmarkPie(auxPie);

                        }
                 });

                 service.coveragecountry(filterVisit, (data, error) => {
                    if(data && data.data) {
                       debugger;

                       var attr:any[] = [];
                       var attrArray:any[] = [];
                       var seriesHidden:any[] = [];
                       var attrColors:any[] = [];
                       var pie:any[] = [];
                       var countSerie = 0;
                       data.data.forEach(element => {
                          let key = element.code;
                          if(!attr[key]){
                              attr[key] = {key:key, name:element.name};
                              attrArray.push({key:key, name:element.name});
                              seriesHidden[key] = false;

                              attrColors[key] = colors[countSerie % colors.length];
                              countSerie++;
                          }

                          if(!pie[key]){
                             pie[key] = 0;
                          }

                          pie[key] =  pie[key] + element["ammount"];

                       });

                       let byDate:any[] = [];

                        data.data.forEach(element => {
                            let date = element.dateLabel;
                            if(!byDate[date]){
                               byDate[date] = element;
                            }

                            let code = element.code;
                            byDate[date]["ammount"+code] = element["ammount"];
                            byDate[date]["ranking"+code] = element["ranking"];
                            
                        });

                        let aux:any[] = [];
                        for (const key in byDate) {
                           if (Object.prototype.hasOwnProperty.call(byDate, key)) {
                               const element = byDate[key];
                               aux.push(element);
                           }
                        }


                        let auxPie:any[] = [];
                        for (const key in pie) {
                            if (Object.prototype.hasOwnProperty.call(pie, key)) {
                                const element = pie[key];
                                auxPie.push({key:key,value:element});
                            }
                        }


                       setAttrcoverageColors(attrColors);
                       setAttrcoverageSerie(attrArray);
                       setAttrcoverage(data.data);
                       setAttrcoveragegra(aux);
                       setAttrcoverageSerieHidden(seriesHidden);
                       setAttrcoveragePie(auxPie);

                    }
             });
 
 
          }

           
        }

    }
        
  }

  const searchFilter = (filter) => {
    setFilterVisit(filter);
    searchEvolutionVisit(filter);
  }

  useEffect(() => {
    searchEvolutionVisit(filterVisit);
  }, []);


  const onClickLegent = (value, series, setSeries,prefix) => {
    
    let id = value.dataKey.replace(prefix,"");
    setSeries(currentValueform => ({...currentValueform, [id]: !series[id]}));
}

  return (
    <CrudEmpty i18n={"attrcountry"}>
        <Grid container style={{background:"white", padding: width > 767?"0.3em 1em 1em":"0.3em 0.2em 1em", borderRadius: "3px", minHeight: "calc(100vh - 250px)", maxWidth:"100%", paddingTop:"1.2em"}}>
            <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                <Filter t={t} i18n={"attrcountry"} searchFilter={searchFilter} filter={filterVisit} titlebutton={t("attrcountry.titlebutton")} estructure={createEstructureFilter}  hiddenButton={true} expanded={true} isHiddenCancel={true}/> 
            </Grid>
            <Grid item sm={3} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                <PanelRounded mobile={width < 768}>
                        <h3>{t("attrcountry.pietypes.visit")}</h3>
                        <h6>{t("attrcountry.pietypes.visitsubtitle")}</h6>
                        <Grid container>
                            <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            {attrlensPie && attrlensPie.length > 0 &&    <ResponsiveContainer aspect={ width> 767 ? 1.2 : 1.0}  width='100%'>
                                        <PieChart>
                                            <Tooltip />
                                            <Legend />
                                            <Pie data={attrlensPie} dataKey="value" nameKey="key" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d"  label>{attrlensPie.map((entry, index) => <Cell fill={attrlensColors[entry["key"]]}/>)}</Pie>
                                        </PieChart>
                                    </ResponsiveContainer> }
                            </Grid>
                                
                        </Grid>
                </PanelRounded> 
            </Grid>
            <Grid item sm={9} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                <PanelRounded mobile={width < 768}>
                        <h3>{t("attrcountry.evolutiontype.title")}</h3>
                        <h6>{t("attrcountry.evolutiontype.subtitle")}</h6>
                        <Grid container>
                            <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                {attrlensgra && attrlens.length > 0 && 
                                    <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                        <LineChart data={attrlensgra}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="dateLabel" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                {(attrlensSerie).map((row) => {
                                                    return  <Line dataKey={"ammount"+row.key} stroke={attrlensColors[row.key]} name={row.name}/>
                                                })}
                                        </LineChart>
                                    </ResponsiveContainer>}
                            </Grid>
                        </Grid>
                </PanelRounded> 
            </Grid>
            <Grid item sm={3} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                <PanelRounded mobile={width < 768}>
                        <h3>{t("attrcountry.piemark.visit")}</h3>
                        <h6>{t("attrcountry.piemark.visitsubtitle")}</h6>
                        <Grid container>
                            <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            {attrmarkPie && attrmarkPie.length > 0 &&    <ResponsiveContainer aspect={ width> 767 ? 1.2 : 1.0}  width='100%'>
                                        <PieChart>
                                            <Tooltip />
                                            <Legend />
                                            <Pie data={attrmarkPie} dataKey="value" nameKey="key" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d"  label>{attrmarkPie.map((entry, index) => <Cell fill={attrmarkColors[entry["key"]]}/>)}</Pie>
                                        </PieChart>
                                    </ResponsiveContainer> }
                            </Grid>
                            
                                
                        </Grid>
                </PanelRounded> 
            </Grid>
            <Grid item sm={9} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                <PanelRounded mobile={width < 768}>
                        <h3>{t("attrcountry.evolutionmark.title")}</h3>
                        <h6>{t("attrcountry.evolutionmark.subtitle")}</h6>
                        <Grid container>
                            <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                {attrmarkgra && attrmark.length > 0 && 
                                    <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                        <LineChart data={attrmarkgra}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="dateLabel" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend onClick={(evet) => onClickLegent(evet,attrmarkSerieHidden,setAttrmarkSerieHidden,"ammount")} wrapperStyle={{fontSize: "0.8em"}} />
                                                {(attrmarkSerie).map((row) => {
                                                    return  <Line dataKey={"ammount"+row.key} stroke={attrmarkColors[row.key]} name={row.name}  hide={attrmarkSerieHidden[row.key]}/>
                                                })}
                                        </LineChart>
                                    </ResponsiveContainer>}
                            </Grid>
                        </Grid>
                </PanelRounded> 
            </Grid>
            <Grid item sm={3} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                <PanelRounded mobile={width < 768}>
                        <h3>{t("attrgeneral.piecoverage.visit")}</h3>
                        <h6>{t("attrgeneral.piecoverage.visitsubtitle")}</h6>
                        <Grid container>
                            <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            {attrcoveragePie && attrcoveragePie.length > 0 &&    <ResponsiveContainer aspect={ width> 767 ? 1.2 : 1.0}  width='100%'>
                                        <PieChart>
                                            <Tooltip />
                                            <Legend />
                                            <Pie data={attrcoveragePie} dataKey="value" nameKey="key" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d"  label>{attrcoveragePie.map((entry, index) => <Cell fill={attrcoverageColors[entry["key"]]}/>)}</Pie>
                                        </PieChart>
                                    </ResponsiveContainer> }
                            </Grid>
                            
                                
                        </Grid>
                </PanelRounded> 
            </Grid>
            <Grid item sm={9} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                <PanelRounded mobile={width < 768}>
                        <h3>{t("attrgeneral.evolutioncoverage.title")}</h3>
                        <h6>{t("attrgeneral.evolutioncoverage.subtitle")}</h6>
                        <Grid container>
                            <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                {attrcoveragegra && attrcoverage.length > 0 && 
                                    <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                        <LineChart data={attrcoveragegra}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="dateLabel" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend onClick={(evet) => onClickLegent(evet,attrcoverageSerieHidden,setAttrcoverageSerieHidden,"ammount")} wrapperStyle={{fontSize: "0.8em"}} />
                                                {(attrcoverageSerie).map((row) => {
                                                    return  <Line dataKey={"ammount"+row.key} stroke={attrcoverageColors[row.key]} name={row.name}  hide={attrcoverageSerieHidden[row.key]}/>
                                                })}
                                        </LineChart>
                                    </ResponsiveContainer>}
                            </Grid>
                        </Grid>
                </PanelRounded> 
            </Grid>
        </Grid>

        <ToastContainer />
    </CrudEmpty>
  );
}


const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: props.mobile? '1em 0.2em' : '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    minHeight:"80px",
    "& h3":{
        color: "black",
        position: "absolute",
        fontSize: "1em",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    },
    "& h6": {
        color: "#434343",
        position: "absolute",
        top: "-1.2em",
        fontSize:"0.8em",
        left: "3.5em",
        fontStyle:"italic",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",
    }

  }));