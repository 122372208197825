import React, {useEffect,useState} from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { analyticserieService as service} from '../../../../services';
import CrudEmpty from "../../../crud/CrudEmpty"
import styled from "styled-components";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer, PieChart, Pie, BarChart, Bar } from 'recharts';
import { ToastContainer, toast } from 'react-toastify';
import CustomPaginationActionsTable from '../../../table/Table';
import CustomPaginationActionsTableInMemory from '../../../table/TableInMemory';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../../../filter/Filter';
const moment = require("moment");



export default function SerieGeneral() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterVisit, setFilterVisit] = React.useState({from: moment().add(-30,'days').format("YYYY-MM-DD")+"T00:00:00Z", to:moment().format("YYYY-MM-DD")+"T00:00:00Z", day:true,week:false,month:false});
  const [colors, setColors] = React.useState<any>(["#0C0AF5","#0A48FF","#0279E8","#0AC9FF","#0AF5EA","#F75696","#D64BC8","#CE5FED","#924BD6","#7D56F7","#FF7881","#FFD996","#E88D61","#D761E8","#8E6BFF","#A8ADFF","#7DFFB3","#E8DA7D","#FF937D","#4D01EB","#FF4F2B","#4900E8","#E5E800","#FFAD7D","#0DFFCF","#9CFFEB","#E6E866","#C066E8","#FFAD7D","#0DD5FF","#FF782B","#9E00E8","#79E800","#FFC67D","#0DD7FF","#CDE6B3","#0DD8FF","#9B00E8","#FF620D","#EAE97E","#96EB7E","#A86FE8","#D49A68","#4F6AD1","#EC1900"]);
  const [dataEvolutionVisitSerieColors, setDataEvolutionVisitSerieColors] = React.useState<any>([]);
  const [dataEvolutionVisitSerieHidden, setDataEvolutionVisitSerieHidden] = React.useState<any>([]);
  const [dataEvolutionVisitSerie, setDataEvolutionVisitSerie] = React.useState<any>([]);
  const [dataEvolutionVisit, setDataEvolutionVisit] = React.useState<any>([]);
  const [dataEvolutionVisitList, setDataEvolutionVisitList] = React.useState<any>([]);

  const [dataEvolutionSearchSerieColors, setDataEvolutionSearchSerieColors] = React.useState<any>([]);
  const [dataEvolutionSearchSerieHidden, setDataEvolutionSearchSerieHidden] = React.useState<any>([]);
  const [dataEvolutionSearchSerie, setDataEvolutionSearchSerie] = React.useState<any>([]);
  const [dataEvolutionSearch, setDataEvolutionSearch] = React.useState<any>([]);
  const [dataEvolutionSearchList, setDataEvolutionSearchList] = React.useState<any>([]);


  const [visitTop10, setVisitTop10] = React.useState<any>([]);
  const [searchTop10, setSearchTop10] = React.useState<any>([]);
  const [marketTop10, setMarketTop10] = React.useState<any>([]);

  const [dataEvolutionSearchMarket, setDataEvolutionSearchMarket] = React.useState<any>([]);
  const [dataEvolutionSearchMarketColors, setDataEvolutionSearchMarketColors] = React.useState<any>([]);
  const [dataEvolutionSearchMarketHidden, setDataEvolutionSearchMarketHidden] = React.useState<any>([]);
  const [dataEvolutionSearchMarketSerie, setDataEvolutionSearchMarketSerie] = React.useState<any>([]);
  const [dataEvolutionSearchMarketList, setDataEvolutionSearchMarketList] = React.useState<any>([]);

  const [width, setWidth] = useState<number>(window.innerWidth);

  const createEstructureFilter =  [
    {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 3
    },
    {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
    }
];


  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);


  const searchEvolutionVisit = (filterVisit) => {

       if(!filterVisit.from) {
            toast.error(t('seriegeneral.error.from'), {position: toast.POSITION.TOP_CENTER })
       } else if(!filterVisit.to) {
            toast.error(t('seriegeneral.error.to'), {position: toast.POSITION.TOP_CENTER })
       } else {
          let december = moment("2022-12-01");
          let daysDiffStart = moment(filterVisit.from).diff(december, 'days');

          if(daysDiffStart < 0){
            toast.error(t('seriegeneral.error.start'), {position: toast.POSITION.TOP_CENTER })
          } else {
            let daysDiff = moment(filterVisit.to).diff(moment(filterVisit.from), 'days');
            if(daysDiff < 0){
                toast.error(t('seriegeneral.error.diff'), {position: toast.POSITION.TOP_CENTER })
            } else {
                 filterVisit['day'] = daysDiff < 32;
                 filterVisit['week'] = daysDiff >= 32 && daysDiff < 64;
                 filterVisit['month'] = daysDiff >= 64;

                 service.visit(filterVisit, (data, error) => {
                     if(data && data.data) {
                         var series:any[] = [];
                         var seriesHidden:any[] = [];
                         var seriesColors:any[] = [];
                         var countSerie = 0;
                         data.data.forEach(element => {
                            let key = element.serie.id;
                            if(!series[key]){
                                series[key] = {key:key, name:element.serie.name};
                                seriesHidden[key] = false;
                                
                                seriesColors[key] = colors[countSerie % colors.length];
                                countSerie++;
                            }
                         });


                         let byDate:any[] = [];

                         data.data.forEach(element => {
                             let date = element.dateLabel;
                             if(!byDate[date]){
                                byDate[date] = element;
                             }

                             let idSerie = element.serie.id;
                             byDate[date]["ammount"+idSerie] = element["ammount"]
                             byDate[date]["ranking"+idSerie] = element["ranking"]
                             
                         });

                         let aux:any[] = [];
                         
                         for (const key in byDate) {
                            if (Object.prototype.hasOwnProperty.call(byDate, key)) {
                                const element = byDate[key];
                                aux.push(element);
                                
                            }
                         }




                         setDataEvolutionVisitSerieHidden(seriesHidden);
                         setDataEvolutionVisitSerieColors(seriesColors);
                         setDataEvolutionVisit(aux);
                         setDataEvolutionVisitSerie(series);
                         setDataEvolutionVisitList(data.data);

                     }
                 });
 
                 service.search(filterVisit, (data, error) => {
                     if(data && data.data) {
                        var series:any[] = [];
                        var seriesHidden:any[] = [];
                        var seriesColors:any[] = [];
                        var countSerie = 0;
                        data.data.forEach(element => {
                           let key = element.serie.id;
                           if(!series[key]){
                               series[key] = {key:key, name:element.serie.name};
                               seriesHidden[key] = false;
                               
                               seriesColors[key] = colors[countSerie % colors.length];
                               countSerie++;
                           }
                        });


                        let byDate:any[] = [];

                        data.data.forEach(element => {
                            let date = element.dateLabel;
                            if(!byDate[date]){
                               byDate[date] = element;
                            }

                            let idSerie = element.serie.id;
                            byDate[date]["ammount"+idSerie] = element["ammount"]
                            byDate[date]["ranking"+idSerie] = element["ranking"]
                            
                        });

                        let aux:any[] = [];
                        
                        for (const key in byDate) {
                           if (Object.prototype.hasOwnProperty.call(byDate, key)) {
                               const element = byDate[key];
                               aux.push(element);
                               
                           }
                        }




                        setDataEvolutionSearchSerieHidden(seriesHidden);
                        setDataEvolutionSearchSerieColors(seriesColors);
                        setDataEvolutionSearch(aux);
                        setDataEvolutionSearchSerie(series);
                        setDataEvolutionSearchList(data.data);

                    }
                 }); 
 
 
                 service.searchMarket(filterVisit, (data, error) => {
                     if(data && data.data) {
                        var series:any[] = [];
                        var seriesHidden:any[] = [];
                        var seriesColors:any[] = [];
                        var countSerie = 0;
                        data.data.forEach(element => {
                           let key = element.serie.id;
                           if(!series[key]){
                               series[key] = {key:key, name:element.serie.name};
                               seriesHidden[key] = false;
                               
                               seriesColors[key] = colors[countSerie % colors.length];
                               countSerie++;
                           }
                        });


                        let byDate:any[] = [];

                        data.data.forEach(element => {
                            let date = element.dateLabel;
                            if(!byDate[date]){
                               byDate[date] = element;
                            }

                            let idSerie = element.serie.id;
                            byDate[date]["ammount"+idSerie] = element["ammount"]
                            byDate[date]["ranking"+idSerie] = element["ranking"]
                            
                        });

                        let aux:any[] = [];
                        
                        for (const key in byDate) {
                           if (Object.prototype.hasOwnProperty.call(byDate, key)) {
                               const element = byDate[key];
                               aux.push(element);
                               
                           }
                        }




                        setDataEvolutionSearchMarketHidden(seriesHidden);
                        setDataEvolutionSearchMarketColors(seriesColors);
                        setDataEvolutionSearchMarket(aux);
                        setDataEvolutionSearchMarketSerie(series);
                        setDataEvolutionSearchMarketList(data.data);
                     }
                 });
 
                
 
                 service.visitTop10Date(filterVisit, (data, error) => {
                     if(data && data.data) {
                         setVisitTop10(data.data);
                     }
                 });

                 service.searchTop10Date(filterVisit, (data, error) => {
                    if(data && data.data) {
                        setSearchTop10(data.data);
                    }
                });

                 
                service.marketTop10Date(filterVisit, (data, error) => {
                    if(data && data.data) {
                        setMarketTop10(data.data);
                    }
                });
 

          }

           
        }

    }
        
  }

  const searchFilter = (filter) => {
    setFilterVisit(filter);
    searchEvolutionVisit(filter);
  }

  useEffect(() => {
    searchEvolutionVisit(filterVisit);
  }, []);

  const paintvalue = {
    codeCountry: (value) => {
        if (value) {
          return <img src={"/"+value.toLowerCase()+".png"} style={{width:"40px"}} />
        } else {
          return <></>
        }
    },
  }

  const onClickLegent = (value, series, setSeries,prefix) => {
    
        let id = value.dataKey.replace(prefix,"");
        setSeries(currentValueform => ({...currentValueform, [id]: !series[id]}));
  }

  return (
    <CrudEmpty i18n={"seriegeneral"}>
        <Grid container style={{background:"white", padding: width > 767?"0.3em 1em 1em":"0.3em 0.2em 1em", borderRadius: "3px", minHeight: "calc(100vh - 250px)", maxWidth:"100%", paddingTop:"1.2em"}}>
        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <Filter t={t} i18n={"seriegeneral"} searchFilter={searchFilter} filter={filterVisit} titlebutton={t("seriegeneral.titlebutton")} estructure={createEstructureFilter}  hiddenButton={true} expanded={true} isHiddenCancel={true}/> 
        </Grid>
        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("seriegeneral.top.visit")}</h3>
                    <h6>{t("seriegeneral.top.visitsubtitle")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            <CustomPaginationActionsTable hiddenpagination={true}  t={t} i18n={"seriegeneral"}  rows={visitTop10} fields={["serie","ammount","ranking"]} fieldId={"id"} rowsPerPage={10} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                        </Grid> 
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("seriegeneral.evolution.title")}</h3>
                    <h6>{t("seriegeneral.evolution.subtitle")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em", textAlign:"center"}}>
                                 <h5>{t("seriegeneral.visit")}</h5>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"0px", padding:"0.2em"}}>
                            {dataEvolutionVisit && dataEvolutionVisit.length > 0 && dataEvolutionVisitSerie && dataEvolutionVisitSerie.length > 0 &&
                                <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                    <LineChart data={dataEvolutionVisit}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend onClick={(evet) => onClickLegent(evet,dataEvolutionVisitSerieHidden,setDataEvolutionVisitSerieHidden,"ammount")} wrapperStyle={{fontSize: "0.8em"}}/>
                                            {(dataEvolutionVisitSerie).map((row) => {
                                                return  <Line dataKey={"ammount"+row.key} stroke={dataEvolutionVisitSerieColors[row.key]} name={row.name} hide={dataEvolutionVisitSerieHidden[row.key]}/>
                                            })}
                                           
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em", textAlign:"center"}}>
                                 <h5>{t("seriegeneral.rankingposition")}</h5>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                            {dataEvolutionVisit && dataEvolutionVisit.length > 0 &&  dataEvolutionVisitSerie && dataEvolutionVisitSerie.length > 0 &&
                                <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                    <LineChart data={dataEvolutionVisit}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend onClick={(evet) => onClickLegent(evet,dataEvolutionVisitSerieHidden,setDataEvolutionVisitSerieHidden,"ranking")} wrapperStyle={{fontSize: "0.8em"}}/>
                                            {(dataEvolutionVisitSerie).map((row) => {
                                                return  <Line dataKey={"ranking"+row.key} stroke={dataEvolutionVisitSerieColors[row.key]} name={row.name} hide={dataEvolutionVisitSerieHidden[row.key]}/>
                                            })}
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("seriegeneral.ranking.visitdata")}</h3>
                    <h6>{t("seriegeneral.ranking.visitsubtitledata")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                <CustomPaginationActionsTableInMemory  t={t} i18n={"seriegeneral"}  rows={dataEvolutionVisitList} fields={["serie.name","dateLabel","ammount","ranking"]} fieldId={"id"} rowsPerPage={20} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                        </Grid> 
                    </Grid>
            </PanelRounded> 
        </Grid>
        
        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("seriegeneral.top.search")}</h3>
                    <h6>{t("seriegeneral.top.searchsubtitle")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            <CustomPaginationActionsTable hiddenpagination={true}  t={t} i18n={"seriegeneral"}  rows={searchTop10} fields={["serie","ammount","ranking"]} fieldId={"id"} rowsPerPage={10} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                        </Grid> 
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("seriegeneral.evolutionsearch.title")}</h3>
                    <h6>{t("seriegeneral.evolutionsearch.subtitle")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em", textAlign:"center"}}>
                                 <h5>{t("seriegeneral.search")}</h5>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            {dataEvolutionSearch && dataEvolutionSearch.length > 0 && 
                                <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                    <LineChart data={dataEvolutionSearch}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend onClick={(evet) => onClickLegent(evet,dataEvolutionSearchSerieHidden,setDataEvolutionSearchSerieHidden,"ammount")} wrapperStyle={{fontSize: "0.8em"}}/>
                                            {(dataEvolutionSearchSerie).map((row) => {
                                                return  <Line dataKey={"ammount"+row.key} stroke={dataEvolutionSearchSerieColors[row.key]} name={row.name} hide={dataEvolutionSearchSerieHidden[row.key]}/>
                                            })}
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em", textAlign:"center"}}>
                                 <h5>{t("seriegeneral.rankingposition")}</h5>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                            {dataEvolutionSearch && dataEvolutionSearch.length > 0 && 
                                <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                    <LineChart data={dataEvolutionSearch}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend onClick={(evet) => onClickLegent(evet,dataEvolutionSearchSerieHidden,setDataEvolutionSearchSerieHidden,"ranking")} wrapperStyle={{fontSize: "0.8em"}}/>
                                            {(dataEvolutionSearchSerie).map((row) => {
                                                return  <Line dataKey={"ranking"+row.key} stroke={dataEvolutionSearchSerieColors[row.key]} name={row.name} hide={dataEvolutionSearchSerieHidden[row.key]}/>
                                            })}
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("seriegeneral.ranking.searchdata")}</h3>
                    <h6>{t("seriegeneral.ranking.searchsubtitledata")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                <CustomPaginationActionsTableInMemory  t={t} i18n={"seriegeneral"}  rows={dataEvolutionSearchList} fields={["serie.name","dateLabel","ammount","ranking"]} fieldId={"id"} rowsPerPage={20} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                        </Grid> 
                    </Grid>
            </PanelRounded> 
        </Grid>
        
        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("seriegeneral.top.market")}</h3>
                    <h6>{t("seriegeneral.top.marketsubtitle")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            <CustomPaginationActionsTable hiddenpagination={true}  t={t} i18n={"seriegeneral"}  rows={marketTop10} fields={["serie","ammount","ranking"]} fieldId={"id"} rowsPerPage={10} rowsTotal={dataEvolutionVisit.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                        </Grid> 
                    </Grid>
            </PanelRounded> 
        </Grid>
        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("seriegeneral.evolutionmarket.title")}</h3>
                    <h6>{t("seriegeneral.evolutionmarket.subtitle")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em", textAlign:"center"}}>
                                 <h5>{t("seriegeneral.search")}</h5>
                        </Grid>
                    <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                            {dataEvolutionSearchMarket && dataEvolutionSearchMarket.length > 0 && 
                                <ResponsiveContainer aspect={ width> 767 ? 4.0 : 1.5}  width='100%'>
                                    <LineChart data={dataEvolutionSearchMarket}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend onClick={(evet) => onClickLegent(evet,dataEvolutionSearchMarketHidden,setDataEvolutionSearchMarketHidden,"ammount")} wrapperStyle={{fontSize: "0.8em"}}/>
                                            {(dataEvolutionSearchMarketSerie).map((row) => {
                                                return  <Line dataKey={"ammount"+row.key} stroke={dataEvolutionSearchMarketColors[row.key]} name={row.name} hide={dataEvolutionSearchMarketHidden[row.key]}/>
                                            })}
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em", textAlign:"center"}}>
                                 <h5>{t("seriegeneral.rankingposition")}</h5>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
                            {dataEvolutionSearchMarket && dataEvolutionSearchMarket.length > 0 && 
                                <ResponsiveContainer  aspect={ width> 767 ? 4.0 : 1.5} width='100%'>
                                    <LineChart data={dataEvolutionSearchMarket}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dateLabel" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Legend onClick={(evet) => onClickLegent(evet,dataEvolutionSearchMarketHidden,setDataEvolutionSearchMarketHidden,"ranking")} wrapperStyle={{fontSize: "0.8em"}}/>
                                            {(dataEvolutionSearchMarketSerie).map((row) => {
                                                return  <Line dataKey={"ranking"+row.key} stroke={dataEvolutionSearchMarketColors[row.key]} name={row.name} hide={dataEvolutionSearchMarketHidden[row.key]}/>
                                            })}
                                    </LineChart>
                                </ResponsiveContainer>}
                        </Grid>
                    </Grid>
            </PanelRounded> 
        </Grid>

        <Grid item sm={12} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
            <PanelRounded mobile={width < 768}>
                    <h3>{t("seriegeneral.ranking.marketdata")}</h3>
                    <h6>{t("seriegeneral.ranking.marketsubtitledata")}</h6>
                    <Grid container>
                        <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em"}}>
                                <CustomPaginationActionsTableInMemory  t={t} i18n={"seriegeneral"}  rows={dataEvolutionSearchList} fields={["serie.name","dateLabel","ammount","ranking"]} fieldId={"id"} rowsPerPage={20} rowsTotal={dataEvolutionSearchMarketList.length} deleteaction={false} editable={false} paintCell={{}}  moreActions={[]}  />
                        </Grid> 
                    </Grid>
            </PanelRounded> 
        </Grid>

        </Grid>

        <ToastContainer />
    </CrudEmpty>
  );
}


const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: props.mobile? '1em 0.2em' : '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    minHeight:"80px",
    "& h3":{
        color: "black",
        position: "absolute",
        fontSize: "1em",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    },
    "& h6": {
        color: "#434343",
        position: "absolute",
        top: "-1.2em",
        fontSize:"0.8em",
        left: "3.5em",
        fontStyle:"italic",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",
    }

  }));