import jwtDecode from 'jwt-decode';

interface TokenInfo {
    exp: number;
  }

export const isNotLoginLogin = () => {
    var expisold = false;
    if(localStorage.getItem("token") != null && localStorage.getItem("token") != "" && localStorage.getItem("token") != undefined && localStorage.getItem("token") != "undefined"){
      const token:string = localStorage.getItem("token")+'';
      const tokende =  jwtDecode<TokenInfo>(token);
      if (Date.now() >= tokende.exp * 1000) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

export const idUser = () => {
  const user =  localStorage.getItem("user");
  if(user){
    return JSON.parse(user)['id'];
  } else {
    return 0;
  }
}