import React, {useEffect} from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Copyright from '../commons/Copyright';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from './Menu';
import MenuMui from '@mui/material/Menu';
import { useHistory } from "react-router-dom";
import {idUser} from "../../utils/session";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InventoryIcon from '@mui/icons-material/Inventory';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Block } from '@mui/icons-material';
import TextField from '@mui/material/TextField';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));


const TextFieldHeader = styled(TextField)`
  margin-left: 2em;
  width: 300px;
  max-width: 50%;
  & input: {
    color: white;
  }
  & label.MuiFormLabel-colorPrimary{
    color: white !important;
  }
  & label.Mui-focused {
    color: white !important;
  }
  & input.MuiInput-input {
    color: white !important;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white !important;
    transform: none;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
  }

`;





export default function Layout(props) {

  const t = props.t;
  const [search, setSearch] = React.useState(props.search?true:false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(window.innerWidth > 768);
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const history = useHistory();



  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };


  useEffect(() => {

  }, []);

  const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
      setAnchorEl(null);
 };

 const closeSession = function(){
    localStorage.clear();
    history.push('/');
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter' && props.code != '' && props.searchByCode) {
        props.searchByCode(props.code)
    } else {
      console.log("TECLA", e.key, "CODIGO", props.code);
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img src="/logoletras.png" style={{width:"150px", maxWidth:"30%"}}/>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {props.title ? props.title : t('general.nameapp')}
            {search && 
              <TextFieldHeader variant="standard" id={"code"} name={"code"} onKeyDown={_handleKeyDown}  label={props.label} value={props.code} onChange={(evt) => props.setCode(evt.target.value)} type={"text"}  inputProps={{autoComplete: 'off'}}  InputLabelProps={{shrink:true}} autoComplete="off"/>
  
            }
          </Typography>
          
          <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
      
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <MenuMui
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{top:"2em"}}
              >
                <MenuItem><Link to={"/myaccount/"+idUser()} style={{ color:'inherit',textDecoration: 'none' }} replace>{t('general.myprofile')}</Link></MenuItem>
                <MenuItem onClick={closeSession}>{t('general.closession')}</MenuItem>
              </MenuMui>
            </div>
        </Toolbar>
        </AppBar>
        <Menu open={open} handleDrawerClose={handleDrawerClose} t={t}/>
        <Box component="main" sx={{ flexGrow: 1, p: 0}}>
            <DrawerHeader />
            {props.children}
            <Box sx={{marginTop:"3em"}}>
              <Copyright color={"#2f3354"}/>
            </Box>
        </Box>
        <div id="loadingpanel" style={{display:"none", position:"fixed", top:"0px", left: "0px", width: "100%", height: "100%", background:"rgba(0,0,0,0.6)", zIndex:"10000", color:"white", fontWeight:"bold", alignItems: "center",justifyContent: "center", flexDirection: "column"}}>
                <span>Realizando la operación ...</span>
        </div>
      </Box>
  );

}

