import React, {useEffect,useState} from 'react';
import Layout from '../../layout/Layout';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { analyticsmanufactureService as service} from '../../../services';
import CrudEmpty from "../../crud/CrudEmpty"
import styled from "styled-components";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer, PieChart, Pie, BarChart, Bar } from 'recharts';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const moment = require("moment");



export default function Contact() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [width, setWidth] = useState<number>(window.innerWidth);



  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);


  return (
    <CrudEmpty i18n={"contact"}>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px", minHeight: "calc(100vh - 250px)", maxWidth:"100%", paddingTop:"1.2em"}}>
        <Grid item sm={3} xs={12} style={{marginTop:"10px", padding:"0.2em"}}></Grid>
            <Grid item sm={6} xs={12} style={{marginTop:"10px", padding:"0.2em"}}>
              <PanelRounded mobile={width < 768}>
                      <h3>{t("contact.panel.title")}</h3>
                      <h6>{t("contact.panel.subtitle")}</h6>
                      <Grid container>
                          <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em", textAlign:"center"}}>
                              <p>{t("contact.text")}</p>
                          </Grid>

                          <Grid item xs={12} style={{marginTop:"30px", textAlign:"center"}}>
                              <p>{t("contact.email")} <b>info@cinelensesapp.com</b></p>
                          </Grid>

                          <Grid item xs={12} style={{ textAlign:"center"}}>
                              <p>{t("contact.phone")} <b>+34 622522274</b></p>
                          </Grid>

                          <Grid item xs={12} style={{ textAlign:"center"}}>
                              <a href="whatsapp://send?text=Hello&phone=+34622522274&abid=+34622522274" style={{color:"#25D366"}}><WhatsAppIcon fontSize="large"/></a>
                          </Grid>
                          <Grid item xs={12} style={{marginTop:"30px", padding:"0.2em", textAlign:"center"}}>
                              <img src={"/logo.png"} style={{maxWidth: "200px"}}/>
                          </Grid>
                      </Grid>
              </PanelRounded>
            </Grid>

        </Grid>

    </CrudEmpty>
  );
}


const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    minHeight:"80px",
    "& h3":{
        color: "black",
        position: "absolute",
        fontSize: "1em",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    },
    "& h6": {
        color: "#434343",
        position: "absolute",
        top: "-1.2em",
        fontSize:"0.8em",
        left: "3.5em",
        fontStyle:"italic",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",
    }

  }));