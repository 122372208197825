import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';
import MenuElement from './MenuItem';
import MenuParent from './MenuParent';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import PersonIcon from '@mui/icons-material/Person';
import Person4Icon from '@mui/icons-material/Person4';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import CallIcon from '@mui/icons-material/Call';
import CameraIcon from '@mui/icons-material/Camera';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const closedMixinXs = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `0px`,
  });

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .MuiListItemText-root' : {
        display: 'none'
      },
      '& .MuiListItem-root' : {
        paddingLeft: '8px !important',
      }, 
      '& .MuiListItemIcon-root': {
        minWidth: '26px'
      }
      

    }),
    [theme.breakpoints.down('sm')]: {
        ...(!open && {
            ...closedMixinXs(theme),
            '& .MuiDrawer-paper': closedMixinXs(theme),
            '& .MuiListItemText-root' : {
              display: 'none'
            }
          }),
    }
  }),
);

export default function Menu(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(props.open);
  const [openProduct, setOpenProduct] = React.useState(false);


  
  
  const t = props.t;

  const closeAll = (id) => {
    if (id != 1){
      setOpenProduct(false);
    } else {
      setOpenProduct(!openProduct);
    }
    
    
  }

  const isAdmin = () => {
    try {
      let jsonItem = localStorage.getItem('user');
      if(jsonItem){
        let user = JSON.parse(jsonItem);
        return user && user['permissions'] && user['permissions'].includes('ADMIN');
      } else {
        return false;
      }
    } catch(e){
      return false;
    }
    
  }

  React.useEffect(() => {
    setOpen(props.open)
 }, [props.open]);

  return (
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={props.handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <MenuElement url={"/dashboard"} icon={<DashboardIcon />} text={t("menu.dashboard")} />
        <MenuElement url={"/brand"} icon={<BrandingWatermarkIcon />} text={t("menu.brand")} />
        <MenuElement url={"/brandcountry"} icon={<LanguageIcon />} text={t("menu.brandcountry")} />
        {isAdmin() && <MenuElement url={"/branduser"} icon={<PersonIcon />} text={t("menu.branduser")} /> }
        <MenuElement url={"/serie"} icon={<CameraIcon />} text={t("menu.serie")} />
        <MenuElement url={"/serieone"} icon={<CameraIcon />} text={t("menu.serieone")} />
        <MenuElement url={"/seriecountry"} icon={<PublicIcon />} text={t("menu.seriecountry")} />
        {isAdmin() && <MenuElement url={"/serieuser"} icon={<Person4Icon />} text={t("menu.serieuser")} />}
        <MenuElement url={"/search"} icon={<TroubleshootIcon />} text={t("menu.search")} />
        <MenuElement url={"/searchcountry"} icon={<LanguageIcon />} text={t("menu.searchcountry")} />
        {isAdmin() && <MenuElement url={"/searchinmarketplace"} icon={<ScreenSearchDesktopIcon />} text={t("menu.searchinmarketplace")} />}
        <MenuElement url={"/contact"} icon={<CallIcon />} text={t("menu.contact")} />
        
      </Drawer>
  );
}
